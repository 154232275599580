@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@600&family=Lato:wght@100;200;300;400&family=Ubuntu&display=swap');


main {
  width: 100%;

  min-height: 200vh;

  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  background-color: black;

  overflow-x: hidden;
}

section {
  display: flex;
  flex-direction: column;
  align-items: center;
}

h1, h2, h3, label {
  color: white;
  font-family: 'Lato', 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
  font-weight: 300;
  letter-spacing: .15rem;
  text-align: center;
}

h3 {
  font-weight: normal;
}

span, p, ul, ol {
  color: white;
  font-family: 'Lato', 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
  font-weight: 300;
  letter-spacing: .15rem;
}

label {
  font-size: .75em;
}

h1 {
  position: absolute;
  top: max(37vw, 370px);
  z-index: 2;
  text-shadow: black 5px 5px 3px;
  padding: 5px;
  border: solid 2px white;
  background-color: #000A;
}

h2 {
  margin: 3em 1em auto 1em;
}

@media (min-width: 768px) {
  h1 {
    right: 20vw; /* Slightly off-center on computers */
  }
}