.music {
  width: min(calc(100vw - 80px), 1000px);
  margin: 40px;
  line-height: 1.5em;
  text-align: justify;
  text-justify: inter-word;
  align-items: center;
  display: flex;
  flex-direction: column;
}

@media (max-width: 968px) {

}