.featuredColl {
  margin: 20px;
  width: min(calc(100vw - 40px), 1000px);
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.statement {
  width: min(calc(100vw - 80px), 1000px);
  margin: 40px;
  line-height: 1.5em;
  text-align: justify;
  text-justify: inter-word;
  align-items: center;
  display: flex;
  flex-direction: row;
}

.statementIMG {
  width:  min(calc(100vw), 400px);
  margin-right: 20px;
}

@media (max-width: 968px) {
  .statement {
    flex-direction: column;
  }

  .statementIMG {
    margin-right: 0;
  }
}