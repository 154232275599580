.featuredIMG {
  filter: grayscale(100%) brightness(0.65);
  transition: all 100ms;
}

.featuredIMG:hover {
  -webkit-filter: grayscale(0%) brightness(1);
  filter: grayscale(0%) brightness(1);
  transform: scale(1.01);
  transform-origin: 50% 50%;
}
