footer {
  background-image: linear-gradient(to top, #151515 80%, #222);

  padding: 10px 0;
  z-index: 10;
  box-shadow: black 0px 10px 50px;
  display: flex;
  justify-content: center;
}

.social-list > a {
  margin: 0 8px;
  transition: transform 0.2s, opacity 0.2s;
}

.social-list > a:hover {
  opacity: 0.5; /* Slight opacity decrease on hover */
}

.social-list > a > img {
  width: 30px;
}