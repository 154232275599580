.navbar {
  background-image: linear-gradient(to bottom, #151515 65%, #222);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  padding: 10px 0;
  z-index: 10;
  box-shadow: black 0px 10px 50px;
}

.navbar-list {
  list-style-type: none;
  margin: 0;
  padding: 0;
  text-align: center;
}

.navbar-list > li {
  display: inline;
  margin: 0 15px;
  cursor: pointer;
}

.navbar-list > li > a {
  text-decoration: none;
  color: white; /* Black text color */
  font-size: 18px;
  font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  transition: transform 0.2s, opacity 0.2s;
}

.home-item > a {
  font-weight: bold;
}

.navbar-list > li > a:hover {
  transform: scale(1.1); /* Slight grow effect on hover */
  opacity: 0.5; /* Slight opacity decrease on hover */
}

@media (max-width: 768px) {
  .navbar-list > li > a {
    font-size: 15px;
  }  
}